import React, {useEffect} from "react"
import {Link, useSearchParams} from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ConfirmationAlert from "app/components/shared/ConfirmationAlert/ConfirmationAlert";

import { useTranslation } from 'react-i18next';
import Footer from "app/components/shared/Footer/Footer";
import Navbar from "app/components/shared/Navbar/Navbar.tsx";
import CourseItem from "app/components/Courses/CourseItem/CourseItem";
import ProgramItem from "app/components/Programs/ProgramItem/ProgramItem";

import {
  ButtonTypeEnum,
  ButtonColorEnum, 
  ButtonSizeEnum
} from "app/components/shared/enums/global-enums.tsx";
import AppButton from "app/components/shared/AppButton/AppButton.tsx";

import Menu from "app/components/Menu/Menu"

import {AppRoutes} from "app/helpers/routes";
import useStore from "app/store/store";
import "./CoursesList.scss";


export default function CoursesList(){

  const {t} = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams()
  const courses = useStore((state) => state.courses);
  const programs = useStore((state) => state.programs);
  const user = useStore((state)=> state.user);
  const openConfAlert = useStore((state) => state.openConfAlert);
  const storeActions = useStore((state) => state.actions);
  
  useEffect(() =>{
    storeActions.fetchCourses();
    storeActions.fetchPrograms({university_id: user.university_id});
  }, [user, storeActions]);

  const [value, setValue] = React.useState('courses');
  const [filteredCourses, setFilteredCourses] = React.useState([]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    searchParams.delete("program_id");
    setSearchParams(searchParams);
    setValue(newValue);
  };

  let programId = searchParams.get("program_id");

  useEffect(() =>{ 
    
    if (programId){
      setFilteredCourses(courses.filter((course)=>{
        if (course.programs.find((p)=>(p.id === parseInt(programId)))){
          return true;
        }else{
          return false;
        }
      }));
      setValue("coursesFil");
    }else{
      setFilteredCourses(courses);
    }
    

  }, [courses, programId]);

  return (
    <>
      <Navbar/>
      <Container maxWidth="xl">
        <div className="v-space-10"></div>
        <Grid container >
          <Grid item md={1.5}>
            <Menu dashboard={true}/>
          </Grid>
          <Grid item md  className="courses-container">
            <Grid >
              <Grid sx={{paddingLeft:5, paddingRight:5}} container spacing={1}>
                <div className="v-space-5"></div>
                <Grid item md >
                  <Typography variant="span" className="username font-2 cap">
                    {user?.first_name}’s Workspace
                  </Typography>
                </Grid> 
                <Grid item md={3}>
                  
                </Grid> 
                <Grid item md={3} className="d-f jc-r ai-c">
                  <Link to={AppRoutes.collaboratePath}>
                    <AppButton 
                      buttonLabel={t('Collaborate')}
                      buttonType={ButtonTypeEnum.DEFAULT}
                      buttonColor={ButtonColorEnum.FIFTH}
                      ButtonSize={ButtonSizeEnum.MEDIUM}
                    />
                  </Link>
                  <div className="h-space-1"></div>
                  <Link to={AppRoutes.newCoursePath}>
                    <AppButton 
                      buttonLabel={t('New '+user.course)}
                      buttonType={ButtonTypeEnum.DEFAULT}
                      buttonColor={ButtonColorEnum.FOURTH}
                      ButtonSize={ButtonSizeEnum.MEDIUM}
                    />
                  </Link>
                </Grid> 
                <div className="v-space-5"></div>
                <Divider className="w-100"/>
                <div className="v-space-5"></div>
              </Grid>             

              <Grid sx={{paddingLeft:5, paddingRight:5}} container >
                
                <Grid item xs={12}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab
                      value="courses"
                      label={t("All "+user.course +"s")}
                      wrapped
                    />
                    {
                      programId &&
                      <Tab
                        value="coursesFil"
                        label={t(user.course+" ("+programs.find((p)=>(p.id === parseInt(programId)))?.name+")")}
                        wrapped
                      />
                    }
                    <Tab
                      value="programs"
                      label={t(user.program+"s")}
                      wrapped
                    />
                  </Tabs>
                  </Box>
                </Grid>
                <Grid container spacing={5} >
                  <div className="v-space-10"></div>
                    { 
                      (value === "courses" || value === "coursesFil" ) &&
                      filteredCourses.map ((course, key)=>{
                        return (
                          <Grid key={key} item lg={6} className="w-100">
                            <CourseItem key={key} course={course}/>
                          </Grid>
                          )
                      })
                    }
                    { 
                      value === "programs" &&
                      programs.map ((program, key)=>{
                        return (
                          <Grid key={key} item lg={4} className="w-100">
                            <ProgramItem key={key} program={program}/>
                          </Grid>
                          )
                      })
                    }
                </Grid>
              </Grid>

            </Grid>

            <div className="v-space-10"></div>
            
          </Grid>
        </Grid>



      </Container>
      <div className="v-space-10"></div>
      <Footer/>

      {
        openConfAlert &&  <ConfirmationAlert 
          title={t("Confirmation")}
          content={t("delete_course_confirmation")}
          mainAction={()=>storeActions.deleteCurrentCourse()}
          mainActionText={t("Delete Now!")}
          secondaryActionText={t("Cancel")}
        />
      }
    </>
    );
}
