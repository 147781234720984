import Api from 'app/helpers/api'

export const programsSlice = ((set, get) => ({
  programs: [],

  program: {},

  actions: {
    fetchPrograms: async (params) => {
      const api = new Api();
      api.getPrograms(params)
        .then((response) => {
          set((state) => ({ programs: response.data.programs }));
        })
      .catch((err) => console.log(err));
    },

    setProgram: (program) => {
      set((state) => ({ program: program}));
    },

  }

}))