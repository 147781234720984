import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
  ButtonTypeEnum, DataRecordType
} from "app/components/shared/enums/global-enums.tsx";
import AppButton from "app/components/shared/AppButton/AppButton.tsx";
import useStore from "app/store/store";

import './CourseForm.scss' ;
import { useTranslation } from 'react-i18next';


export default function CourseForm({formRef, course}) {

  let [searchParams, ] = useSearchParams();

  let recordType = searchParams.get("type");

  const CourseSchema = Yup.object().shape({
    academic_year_id: Yup.number()
      .required('Required'),
    input_type_id: Yup.number()
        .required('Required'),
    academic_term_id: Yup.number()
      .required('Required'),
    discipline_id: Yup.number()
      .required('Required'),
    name: Yup.string()
      .min(2, 'Too Short!')
      .required('Required'),
    description: Yup.string()
     .min(2, 'Too Short!')
     .required('Required'),
    learning_outcomes: Yup.string()
     .min(2, 'Too Short!')
     .required('Required'),
    measureable_outcomes: Yup.string()
     .min(2, 'Too Short!')
     .required('Required'),
  });


  const { t } = useTranslation();

  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const disciplines = useStore((state) => state.disciplines);
  const programs = useStore((state) => state.programs);
  const inputTypes = useStore((state) => state.inputTypes);
  const academicYears = useStore((state) => state.academicYears);
  const academicTerms = useStore((state) => state.academicTerms);

  let [selectedPrograms, setSelectedPrograms] = useState([]);


  const initValues = {
            academic_year_id: course.academic_year_id? course.academic_year_id: '',
            academic_term_id: course.academic_term_id? course.academic_term_id: '',
            input_type_id: course.input_type_id? course.input_type_id: (recordType=== DataRecordType.ACCREDITATION? '': 1),
            discipline_id: course.discipline_id? course.discipline_id: '',
            name: course.name? course.name: '',
            description: course.description? course.description: '',
            learning_outcomes: course.learning_outcomes? course.learning_outcomes: '',
            measureable_outcomes: course.measureable_outcomes? course.measureable_outcomes: '',
          };

  const storeActions = useStore((state)=> state.actions);

  useEffect(() =>{
    if (course?.programs?.length > 0){
      setSelectedPrograms(course.programs);
    }

  }, [course]);


  useEffect(() =>{
    storeActions.fetchDisciplines();
    storeActions.fetchPrograms({university_id: user.university_id});
    storeActions.fetchInputTypes();
    storeActions.fetchAcademicTerms();
    storeActions.fetchAcademicYears();
    storeActions.setNavigate(navigate);
    storeActions.setCurrentCourse(course);
  }, [user, course, storeActions, navigate]);


  function CustomAutocomplete({course}) {
    return (<Autocomplete
                      id="combo-box-demo"
                      defaultValue = {selectedPrograms}
                      className="programs-select"
                      multiple={true}
                      options={programs}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => <TextField className="bg-white-color " {...params} label={t("Search By "+user.program+" Name")} />}
                      onChange={(event, value) => {
                          setSelectedPrograms(value);
                        }
                      }
                    />)
  }


  return (
  	<>
      <Formik
        enableReinitialize={true} 
        innerRef={formRef}
        initialValues={initValues}
        validationSchema={CourseSchema}
        onSubmit={(values)=>{
            values.id = course.id;
            storeActions.updateOrCreateCourse({course:values, programs: selectedPrograms});
          }
        }
      >
      {({ errors, touched }) => (

        <Form>

        	<Grid container >

            <Grid container spacing={2}  >
              <Grid container  item lg={6} className="d-f ai-t">
                <Grid item lg={12}>
                  <AppButton 
                    isDisabled={true}
                    sx={{fontWeight: 400}}
                    buttonLabel={t('Academic Year & Term')}
                    buttonType={ButtonTypeEnum.DEFAULT}
                    buttonStartIcon={<FormatListBulletedIcon />}
                  />
                </Grid>
                <div className="v-space-3"></div>
                <Grid item className="w-100">
                  <Field  className="dropbox font-2" as="select" id="academic_year_id" name="academic_year_id">
                   <option value="">{t('--Academic Year--')}</option>
                   {
                     academicYears.map((academicYear, key)=>{
                       return(
                         <option key={key} value={academicYear.id}>{academicYear.label}</option>
                         )
                     })
                   }
                  </Field>
                  <div className="v-space-1"></div>
                  {errors.academic_year_id && touched.academic_year_id ? <div className="error-color">{errors.academic_year_id}</div> : null}
                </Grid>
                <div className="v-space-3"></div>
                <Grid item className="w-100">
                  <Field  className="dropbox font-2" as="select" name="academic_term_id">
                    <option value="">{t('--Academic Term--')}</option>
                    {
                      academicTerms.map((academicTerm, key)=>{
                        return(
                          <option key={key} value={academicTerm.id}>{academicTerm.label}</option>
                          )
                      })
                    }
                  </Field>
                  <div className="v-space-1"></div>
                  {errors.academic_term_id && touched.academic_term_id ? <div className="error-color">{errors.academic_term_id}</div> : null}
                </Grid>
                <div className="v-space-5"></div>
              </Grid>

              <Grid container item lg={6} className="d-f " sx={{alignContent: 'start'}}>
                <Grid item lg={12}>
                  <AppButton 
                    isDisabled={true}
                    sx={{fontWeight: 400}}
                    buttonLabel={t('Discipline')}
                    buttonType={ButtonTypeEnum.DEFAULT}
                    buttonStartIcon={<FormatListBulletedIcon />}
                  />
                </Grid>
                <div className="v-space-3"></div>
                <Grid className="w-100">

                  <Field className="dropbox font-2" as="select" name="discipline_id">
                    <option value="">{t('--Discipline--')}</option>
                    {
                      disciplines.map((discipline, key)=>{
                        return(
                          <option key={key}  value={discipline.id}>{discipline.name}</option>
                          )
                      })
                    }
                  </Field>
                  <div className="v-space-1"></div>
                  {errors.discipline_id && touched.discipline_id ? <div className="error-color">{errors.discipline_id}</div> : null}
                </Grid>

              </Grid>
              <div className="v-space-5"></div>
            </Grid>

            <Grid container spacing={2} >
              <Grid container item lg={6} className="d-f " sx={{alignContent: 'start'}}>
                <Grid item lg={12}>
                  <AppButton 
                    isDisabled={true}
                    sx={{fontWeight: 400}}
                    buttonLabel={t('Related '+user.program+'s')}
                    buttonType={ButtonTypeEnum.DEFAULT}
                    buttonStartIcon={<FormatListBulletedIcon />}
                  />
                </Grid>
                <div className="v-space-3"></div>
                <Grid className="w-100">
                  <CustomAutocomplete
                    course={course}
                  />
                  
                  <div className="v-space-1"></div>
                  {errors.discipline_id && touched.discipline_id ? <div className="error-color">{errors.discipline_id}</div> : null}
                </Grid>

              </Grid>

              <Grid item xs={6} >
                <AppButton 
                  isDisabled={true}
                  sx={{fontWeight: 400}}
                  buttonLabel={t('Type')}
                  buttonType={ButtonTypeEnum.DEFAULT}
                  buttonStartIcon={<FormatListBulletedIcon />}
                />
                <div className="v-space-3"></div>
                <Field  className="dropbox font-2 cap" as="select" name="input_type_id">
                  <option value="">{t('--Type--')}</option>
                  {
                    inputTypes.map((inputType, key)=>{
                      if (recordType === DataRecordType.ACCREDITATION && inputType.id === 1){
                        return null;
                      }
                      if (recordType !== DataRecordType.ACCREDITATION && inputType.id !== 1){
                        return null;
                      }
                      return(
                        <option key={key} value={inputType.id}>{inputType.name}</option>
                        )
                    })
                  }
                </Field>
                <div className="v-space-1"></div>
                {errors.input_type_id && touched.input_type_id ? <div className="error-color">{errors.input_type_id}</div> : null}
                <div className="v-space-5"></div>
              </Grid>
              <div className="v-space-5"></div>
            </Grid>

            <Grid  className="w-100" >
              <AppButton 
                isDisabled={true}
                sx={{fontWeight: 400}}
                buttonLabel={t('Name')}
                buttonType={ButtonTypeEnum.DEFAULT}
                buttonStartIcon={<FormatListBulletedIcon />}
              />
              <div className="v-space-3"></div>
              <Field  id="name" className="field w-100 font-2" name="name" placeholder={t('Name')} />
              <div className="v-space-1"></div>
              {errors.name && touched.name ? <div className="error-color">{errors.name}</div> : null}
              <div className="v-space-5"></div>
            </Grid>
        		<Grid  className="w-100">
	            <AppButton 
                isDisabled={true}
                sx={{fontWeight: 400, textTransform: 'capitalize'}}
                buttonLabel={t((recordType ? recordType : user.course )+" Description")}
                buttonType={ButtonTypeEnum.DEFAULT}
                buttonStartIcon={<FormatListBulletedIcon />}
              />
              <div className="v-space-3"></div>
	            <Field as="textarea" id="description" className="font-2 field textarea w-100 cap" name="description" placeholder={t(user.course+" Description")} />
              <div className="v-space-1"></div>
              {errors.description && touched.description ? <div className="error-color">{errors.description}</div> : null}
              <div className="v-space-5"></div>
            </Grid>
            <Grid className="w-100">
              <AppButton 
                isDisabled={true}
                sx={{fontWeight: 400}}
                buttonLabel={t("Learning Outcomes")}
                buttonType={ButtonTypeEnum.DEFAULT}
                buttonStartIcon={<FormatListBulletedIcon />}
              />
              <div className="v-space-3"></div>
	            <Field as="textarea" id="learning_outcomes" name="learning_outcomes" className="field font-2 textarea w-100" placeholder={t("Learning Outcomes")} />
              <div className="v-space-1"></div>
              {errors.learning_outcomes && touched.learning_outcomes ? <div className="error-color">{errors.learning_outcomes}</div> : null}
              <div className="v-space-5"></div>
            </Grid>
            <Grid className="w-100">
              <AppButton 
                isDisabled={true}
                sx={{fontWeight: 400}}
                buttonLabel={t("Measureable Outcomes")}
                buttonType={ButtonTypeEnum.DEFAULT}
                buttonStartIcon={<FormatListBulletedIcon />}
              />
              <div className="v-space-3"></div>
	            <Field as="textarea"
	              id="measureable_outcomes" name="measureable_outcomes" placeholder={t("Measureable Outcomes")}  className="font-2 field textarea w-100"
               />
               <div className="v-space-1"></div>
               {errors.measureable_outcomes && touched.measureable_outcomes ? <div className="error-color">{errors.measureable_outcomes}</div> : null}
            
            </Grid>
          </Grid>
        </Form>
        )}
      </Formik>

    </>
  );
}

