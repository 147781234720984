import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import {useTranslation} from 'react-i18next'
import useStore from 'app/store/store'
import AppButton from 'app/components/shared/AppButton/AppButton.tsx'

import {
  ButtonTypeEnum
} from "app/components/shared/enums/global-enums.tsx";
import './ListCourses.scss'

export default function ListCourses({openLC, setOpenLC, filteredCourses, setFilteredCourses}) {
  const {t} = useTranslation();

  let courses = [...useStore((state) => state.courses)];
  const user = useStore((state) => state.user);
  const [selectedCourse, setSelectedCourse] = useState(null);
  let filteredCourses_ = [...filteredCourses];

  const allCourses = [...new Set(courses.map((course)=>{ 
    let elm = {};
    elm.input_type_id = course.input_type_id;
    elm.label = "("+ course.id + ") " + course.name;
    elm.id = course.id;
    return elm;
  }))];

  const handleAddCourseToList = ()=>{
    let course = courses.find((elm)=> elm.id === selectedCourse.id);
    let courseInx; 
    if (selectedCourse.input_type_id !== 1){
      courseInx = filteredCourses_.findIndex((elm)=> elm.input_type_id !== 1);
    }else{
      courseInx = filteredCourses_.findIndex((elm)=> elm.id === selectedCourse.id);
    }

    if (courseInx >= 0){
      filteredCourses_.splice(courseInx, 1);
    }

    filteredCourses_.push(course);
    setSelectedCourse(null);

    filteredCourses_.sort((a, b) => (a.ord_numb > b.ord_numb ? 1 : -1));
    setFilteredCourses(filteredCourses_);
  }

  const removeCourse = (fc)=>{
    filteredCourses_ = filteredCourses_.filter((elm)=>fc.id!==elm.id);
    setFilteredCourses(filteredCourses_);
  }

  const handleClose = () => {
    setSelectedCourse(null);
    setOpenLC(false);
  };

  return (
    <>
      <Modal
        open={openLC}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="lc-form">
          <div className="lc-title">{t("Select "+user.course+"s")}</div>
          <div className="v-space-4"></div>
          <div>
            {
              filteredCourses_.map((fc, key)=>{
                return(
                  <div className="selected-item bg-main-color-faded" key={key}>

                  {
                    fc.input_type_id === 1 &&
                    <span className='record-type bg-main-color' >C</span> 
                  }

                  ({fc.id}) {fc.name} <span onClick={()=>removeCourse(fc)} className="pointer">✖</span></div>
                  )
              })
            }
            {
              filteredCourses_.length > 0 && 
              <AppButton 
                  onClickFunc={handleClose}
                  buttonLabel={t('Done')}
                  buttonType={ButtonTypeEnum.PRIMARY}
                />
            }
          </div>

          <div className="v-space-6"></div>
          <div>
            <Autocomplete
              id="combo-box-demo"
              options={allCourses}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} label={t("Search By "+user.course+" Name")} />}
              onChange={(event, value) => {
                  let c = courses.find((elm)=> elm.id === value?.id);
                  setSelectedCourse(c);
                }
              }
                />
          </div>
          <div className="v-space-4"></div>
          { selectedCourse && 
            <Grid container spacing={2}>
              <Grid item xs={10} >
                <Typography variant="div" sx={{fontSize: 20}} className="main-color font-1 ">{t("Description")}</Typography>
                <div>

                {
                 selectedCourse.input_type_id === 1 &&
                 <span className='record-type bg-main-color' >C</span> 
                }

                {selectedCourse.description}</div>
                <div className="v-space-2"></div>

                <Typography variant="div" sx={{fontSize: 20}} className="main-color font-1 ">{t("Learning Outcomes")}</Typography>
                <div>{selectedCourse.learning_outcomes}</div>
                <div className="v-space-2"></div>
                <Typography variant="div" sx={{fontSize: 20}} className="main-color font-1 ">{t("Measurable Outcomes")}</Typography>
                <div>{selectedCourse.measureable_outcomes}</div>
                <div className="v-space-2"></div>
              </Grid>
              <Grid item xs={2}>
                <AppButton 
                  onClickFunc={handleAddCourseToList}
                  buttonLabel={t('Add To List')}
                  buttonType={ButtonTypeEnum.DEFAULT}
                />
                
              </Grid>
            </Grid>
          }
        </div>
      </Modal>
    </>
  );
}